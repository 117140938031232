/* global melidata:false */

import runSchedulerTask from '../../utils/task-scheduler';

/**
* Service interface
*/
class ComponentCtrService {
  static notifyPrint(track) {
    this.notify(track, 'print');
  }

  static notify(track, eventName) {
    runSchedulerTask(() => {
      const meliTrack = {};
      melidata('createEventTrack', meliTrack);
      melidata('withPath', '/component', meliTrack);
      melidata('withData', { c_event: eventName, ...track }, meliTrack);
      melidata('sendTrack', meliTrack, 'component_prints');
    });
  }

  static trackRecommendations(recommendationsTrack, type = 'print', privateStream = true, overridePath, useBeacon) {
    runSchedulerTask(() => {
      // eslint-disable-next-line camelcase
      const { event_data: eventData, experiments, experiment } = recommendationsTrack;
      const track = {};
      const melidataTrackType = type === 'print' ? 'createEventTrack' : 'createViewTrack';
      if (typeof melidata === 'function') {
        melidata(melidataTrackType, track);
        if (overridePath) {
          melidata('withPath', overridePath, track);
        } else if (privateStream) {
          melidata('withPath', `/recommendations/${type}`, track);
        } else {
          melidata('withPath', '/recommendations', track);
        }

        melidata('withData', eventData, track);
        if (experiment || experiments) {
          melidata('withExperiments', experiment || experiments, track);
        }
        if (privateStream) {
          melidata('withStream', 'recommendations', track);
        }

        if (useBeacon) {
          track.useBeacon = true;
        }

        melidata('sendTrack', track);
      }
    });
  }
}

/**
* Expose Service
*/
export default ComponentCtrService;
