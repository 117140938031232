/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { string, object } from 'prop-types';
import componentCtrService from '../../../services/component-ctr';
import displayName from '../display-name';

const whenIdle = global.requestIdleCallback ? requestIdleCallback : setTimeout;

function withTracker(WrappedComponent) {
  if (!global.IntersectionObserver) {
    return WrappedComponent;
  }

  class WithTracker extends React.Component {
    /* istanbul ignore next */

    constructor(props) {
      super(props);
      this.track = this.track.bind(this);
    }

    componentDidMount() {
      // I do need to get the dom node here, so disable lint.
      // eslint-disable-next-line
      /* istanbul ignore next */
      // eslint-disable-next-line react/no-find-dom-node
      this.element = ReactDOM.findDOMNode(this);
      if (this.element) { // element is null when WrappedComponent returns null
        this.observer = new IntersectionObserver(this.track, { threshold: 0.5 });
        this.observer.observe(this.element);
      }
    }

    // Tracks the wrapped component.
    track(entries) {
      const { track = null } = this.props;

      if (!entries[0].isIntersecting) return;
      this.observer.unobserve(this.element);
      this.observer.disconnect();
      whenIdle(() => {
        componentCtrService.notifyPrint(track);
      });
    }

    render() {
      // eslint-disable-next-line react/void-dom-elements-no-children
      return <WrappedComponent {...this.props} />;
    }
  }

  WithTracker.displayName = displayName(WithTracker, WrappedComponent);

  WithTracker.propTypes = {
    className: string,
    track: object,
  };

  return WithTracker;
}

export default withTracker;
